export class Selection<T, K = T> {
    private _set: T[] = [];

    constructor(
        private _multiple: boolean = false,
        private _comparator: (value: T) => T | K = (value: T): T => value,
        initialSelection: T | T[] = [],
    ) {
        if (Array.isArray(initialSelection)) {
            this._set = initialSelection;
        } else {
            this._set = [initialSelection];
        }
    }

    public toggle(value: T): void {
        if (this.isSelected(value)) {
            this.deselect(value);
        } else {
            this.select(value);
        }
    }

    public select(...values: T[]): void {
        if (this._multiple) {
            values.forEach((value: T) => {
                this._markSelected(value);
            });
        } else {
            this._set = [values[0]];
        }
    }

    public deselect(...values: T[]): void {
        if (this._multiple) {
            values.forEach((value: T) => {
                this._unmarkSelected(value);
            });
        } else {
            this._set = [];
        }
    }

    public reset(): void {
        this._set = [];
    }

    public get selected(): T[] {
        return [...this._set];
    }

    public hasValue(): boolean {
        return Boolean(this._set.length);
    }

    public isSelected(value: T): boolean {
        return this._comparerValues.some((item: T | K) => this._comparator(value) === item);
    }

    private get _comparerValues(): (T | K)[] {
        return this._set.map((item: T) => this._comparator(item));
    }

    private _markSelected(value: T): void {
        if (!this.isSelected(value)) {
            this._set.push(value);
        }
    }

    private _unmarkSelected(value: T): void {
        const index = this._comparerValues.findIndex(
            (comparerValue: T | K) => this._comparator(value) === comparerValue,
        );

        if (this.isSelected(value)) {
            this._set.splice(index, 1);
        }
    }
}
