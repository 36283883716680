import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackBarType } from '../dialogs/enums';

export const SNACK_BAR_DURATION = 10000;

export const SNACK_BAR_DURATION_SHORT = 5000;

export const SNACK_BAR_CONFIG_DEFAULT: MatSnackBarConfig = {
    duration: SNACK_BAR_DURATION,
    horizontalPosition: 'center',
    panelClass: SnackBarType.Info,
    verticalPosition: 'top',
};

export const SNACK_BAR_CONFIG_DEFAULT_SUCCESS: MatSnackBarConfig = {
    duration: SNACK_BAR_DURATION_SHORT,
    horizontalPosition: 'center',
    panelClass: SnackBarType.Success,
    verticalPosition: 'top',
};

export const SNACK_BAR_CONFIG_ERROR: MatSnackBarConfig = {
    ...SNACK_BAR_CONFIG_DEFAULT,
    panelClass: SnackBarType.Error,
};
