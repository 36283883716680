import {
    cloneDeep, isEqual, isPlainObject, isUndefined, isNull, omit,
} from 'lodash';

export abstract class FormState<T, F> {
    private _formState: F;

    private _formStateInit: F;

    public get formState(): F {
        return this._formState;
    }

    public get formStateInit(): F {
        return this._formStateInit;
    }

    public setFormState(entity: F): void {
        this._formState = entity;
    }

    public setFormStates(entity: T): void {
        this._formStateInit = this._fillEmptyPropertiesWithValue(this.mapEntityToForm(entity), false);
        this._formState = this._fillEmptyPropertiesWithValue(this.mapEntityToForm(entity), false);
    }

    public checkEntityEquality(): boolean {
        return isEqual(this._formStateInit, this._formState);
    }

    public checkEntityEqualityWithOutProperties(withoutProperties: string[]): boolean {
        return isEqual(
            omit(this._formStateInit as object, withoutProperties),
            omit(this._formState as object, withoutProperties),
        );
    }

    public getEntity(): T {
        return this.mapFormToEntity(this._fillEmptyPropertiesWithValue(this._formState));
    }

    protected abstract mapEntityToForm(entity: T): F;

    protected abstract mapFormToEntity(entity: F): T;

    private _fillEmptyPropertiesWithValue(initObj: F, isNewEntity: boolean = true): F {
        const obj: F = cloneDeep(initObj);

        Object.entries(obj).forEach(
            ([key, value]: [string, unknown]) => {
                if (Array.isArray(value)) {
                    value.forEach((itemOfArray: unknown) => {
                        this._fillEmptyPropertiesWithValueInCycle(itemOfArray, isNewEntity);
                    });
                } else if (isPlainObject(value)) {
                    this._fillEmptyPropertiesWithValueInCycle(value, isNewEntity);
                } else {
                    this._fillEmptyProperty(isNewEntity, obj, key, value);
                }
            },
        );

        return obj;
    }

    private _fillEmptyPropertiesWithValueInCycle(obj: unknown, isNewEntity: boolean): void {
        Object.entries(obj).forEach(([keyIn, valueIn]: [string, unknown]) => {
            this._fillEmptyProperty(isNewEntity, obj, keyIn, valueIn);
        });
    }

    private _fillEmptyProperty(isNewEntity: boolean, obj: unknown, key: string, value: unknown): void {
        if (isNewEntity) {
            this._fillEmptyPropertyWithNull(obj, key, value);
        } else {
            this._fillNullPropertyWithEmptyString(obj, key, value);
        }
    }

    private _fillEmptyPropertyWithNull(obj: unknown, keyIn: string, valueIn: unknown): void {
        obj[keyIn] = valueIn === '' || isUndefined(valueIn) ? null : valueIn;
    }

    private _fillNullPropertyWithEmptyString(obj: unknown, keyIn: string, valueIn: unknown): void {
        obj[keyIn] = isNull(valueIn) ? '' : valueIn;
    }
}
