import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MsalInterceptor } from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from './auth';
import { HttpRequestInterceptor } from './http';
import { PermissionsService } from './services';
import { CookieService } from './services/cookie.service';
import { DialogService } from './services/dialog.service';
import { CanDeactivateGuardService } from './services/can-deactivate-guard.service';

const MODULES = [
    AuthModule.forRoot(),
    TranslateModule,
];

@NgModule({
    exports: [
        TranslateModule,
    ],
    imports: [
        ...MODULES,
    ],
})
export class CoreModule {
    public static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                DialogService,
                CookieService,
                CanDeactivateGuardService,
                PermissionsService,
                {
                    multi: true,
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                },
                {
                    multi: true,
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpRequestInterceptor,
                },
            ],
        };
    }
}
