<div class="confirm-popup" [class.wide]="context?.confirmCheckboxLabel">
    <header class="dialog-header">
        <h2 mat-dialog-title>{{ context?.header }}</h2>
    </header>
    <mat-dialog-content>
        <span class="confirm-popup--timer" *ngIf="false">30</span>
        <div class="confirm-popup--msg" [class.block-mode]="context?.confirmCheckboxLabel">
            <p [innerHTML]="context?.title"></p>
            <div class="input-checkbox" *ngIf="context?.confirmCheckboxLabel">
                <mat-checkbox (change)="checkConfirmation()">{{ context?.confirmCheckboxLabel | translate }}
                </mat-checkbox>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="confirm-actions-footer" [ngClass]="context?.footerButtonsClass">
        <button mat-stroked-button id="confirm-dialog-false" [mat-dialog-close]="false" class="btn highlight"
            appClickStopPropagation>
            {{ context?.falseText || 'common.button_no' | translate }}
        </button>
        <button mat-flat-button color="primary" id="confirm-dialog-true" [mat-dialog-close]="true" cdkFocusInitial
            class="btn primary" appClickStopPropagation [disabled]="context?.confirmCheckboxLabel && !isChecked">
            {{ context?.trueText || 'common.button_yes' | translate }}
        </button>
    </mat-dialog-actions>
</div>