import { ISearchResponse } from '../models';

export class SearchResponseUtils {
    public static getEmptySearchResponse<T>(): ISearchResponse<T> {
        return {
            items: [],
            totalCount: 0,
        };
    }
}
