import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { IUserExtended } from '../../../+user';
import { LoggedInUserService } from '../../services';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
    private _user$: BehaviorSubject<IUserExtended> = new BehaviorSubject<IUserExtended>(null);

    constructor(
        private _msalService: MsalService,
        private _loggedInUserService: LoggedInUserService,
    ) {
        this._loggedInUserService.getLoggedInUserInfo().pipe(
            tap((user: IUserExtended) => this.setUser(user)),
            take(1),
        ).subscribe();
    }

    public get userAccount(): AccountInfo {
        return this._msalService.instance.getActiveAccount();
    }

    public setUser(userLogin: IUserExtended): void {
        this._user$.next(userLogin);
    }

    public getUser(): Observable<IUserExtended> {
        return this._user$.asObservable().pipe(
            filter(Boolean),
        );
    }

    public reloadUser(): Observable<IUserExtended> {
        return this._loggedInUserService.getLoggedInUserInfo().pipe(
            tap((user: IUserExtended) => this.setUser(user)),
        );
    }
}
