import { ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IApplicationDialog } from './application-dialog.interface';

export class ApplicationDialog implements IApplicationDialog {
    constructor(public dialogRef: MatDialogRef<IApplicationDialog>) {}

    public close(result?: unknown): void {
        this.dialogRef.close(result);
    }

    public submit(data: unknown): void {
        this.dialogRef.close(data);
    }

    public focusFirstFormField(hostElement: ElementRef): void {
        const firstFormElement = this.findInitialFocusFormElement(hostElement);

        if (firstFormElement) {
            firstFormElement.focus();
        }
    }

    public findInitialFocusFormElement(hostElement: ElementRef): HTMLElement {
        return (hostElement.nativeElement as HTMLElement).querySelector('[cdkFocusInitial]');
    }
}
