import { Directive, OnDestroy } from '@angular/core';
import { ISearchResponse } from './models';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { finalize } from 'rxjs/operators';

interface ISearchParams {
    page?: number;
}

@Directive()
export abstract class AbstractSearchLayoutView<S, T> implements OnDestroy {
    protected totalCount: number;

    protected abstract entitiesList: T[];

    protected abstract searchParameters: S;

    protected page: number;

    protected isLoading: boolean;

    private _destroy$: Subject<void> = new Subject<void>();

    private _subscription: Subscription;

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public loadData(searchParameters: S): void {
        this.isLoading = true;

        this.searchParameters = { ...this.searchParameters, ...searchParameters };

        this._subscription?.unsubscribe();

        this._subscription = this.getData().pipe(
            finalize(() => this.isLoading = false),
            takeUntil(this._destroy$),
        ).subscribe((result: ISearchResponse<T> | T[]) => {
            this.mapData(result);
            this.onLoadData(searchParameters);
            this.scrollToElement((searchParameters as ISearchParams)?.page);
        });
    }

    protected mapData(result: ISearchResponse<T> | T[]): void {
        if (Array.isArray(result)) {
            this.entitiesList = result;
        } else {
            this.entitiesList = result.items;
            this.totalCount = result.totalCount;
        }
    }

    protected abstract getData(): Observable<ISearchResponse<T> | T[]>;

    protected onLoadData(searchParameters?: S): void {
        //should be declared
    }

    protected scrollToElement(page: number): void {
        //should be declared
    }
}
