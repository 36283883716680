import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserExtended } from '../../+user';
import { AppSettings } from '../../core';

@Injectable({ providedIn: 'root' })
export class LoggedInUserService {
    constructor(private _http: HttpClient) { }

    public getLoggedInUserInfo(): Observable<IUserExtended> {
        return this._http.get<IUserExtended>(`${AppSettings.apiHost}/user/v2/user/profile/my`);
    }
}
