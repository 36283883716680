import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot, UrlTree
} from '@angular/router';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService, IPermissions } from '../auth';
import { ROUTES_MAP } from '../constants';
import { RoutesKey } from '../enum';
import { PermissionsService } from './permissions.service';

export const permissionGuard: CanActivateFn =
    (_: ActivatedRouteSnapshot, route: RouterStateSnapshot) => {
        const service = inject(PermissionsService);
        const authService = inject(AuthService);
        const router = inject(Router);

        if (service.permissions) {
            return redirectToPermittedRoute(route, service.permissions, router);
        }

        return authService.waitForAuthenticationToken().pipe(
            switchMap(() => service.getUserPermissions()),
            map((permissions: IPermissions) => redirectToPermittedRoute(route, permissions, router)),
        );
    };

function redirectToPermittedRoute(
    route: RouterStateSnapshot,
    permissions: IPermissions,
    router: Router,
): UrlTree | boolean {
    const routePartially = Object.keys(RoutesKey).find((key: string) => route.url.includes(RoutesKey[key]));
    const permissionsTitle = ROUTES_MAP[routePartially];

    if (!permissionsTitle.length || permissionsTitle.some((permissionName: string) => permissions[permissionName])) {
        return true;
    }

    let redirectedRoute: string;

    if (permissions.licenseViewAll) {
        redirectedRoute = '/licenses';
    } else if (permissions.businessViewAll) {
        redirectedRoute = '/companies';
    } else if (permissions.userViewAll || permissions.roleViewAll) {
        redirectedRoute = '/user-management';
    } else if (permissions.versionUpdateViewAll) {
        redirectedRoute = '/updates';
    } else if (permissions.dashboardViewAll) {
        redirectedRoute = '/dashboard';
    } else if (permissions.reportViewAll) {
        redirectedRoute = '/reports';
    }

    if (redirectedRoute) {
        return router.parseUrl(redirectedRoute);
    }

    return false;
}
