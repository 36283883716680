<div class="alert-dialog">
    <header class="dialog-header">
        <h2 mat-dialog-title>{{ options?.title }}</h2>
    </header>
    <div mat-dialog-content>
        <p [class.with-icon]="options?.type" [innerHTML]="getMessage()"></p>
    </div>
    <div mat-dialog-actions>
        <button mat-flat-button color="primary" [mat-dialog-close]="false" cdkFocusInitial class="btn primary">
            {{ 'common.button_ok' | translate }}
        </button>
    </div>
</div>