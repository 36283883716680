import { Injectable } from '@angular/core';
import { CookieService } from './../../services/cookie.service';

const TOKEN_KEY = 'auth_token';

@Injectable()
export class TokenService {
    constructor(
        private _cookieService: CookieService,
    ) { }

    public get token(): string {
        return this._cookieService.get(TOKEN_KEY);
    }

    public set token(value: string) {
        this._cookieService.set(TOKEN_KEY, value, { path: '/' });
    }

    public clearToken(): void {
        this._cookieService.remove(TOKEN_KEY, { path: '/' });
    }
}
