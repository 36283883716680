import * as KEY_CODES from '@angular/cdk/keycodes';
import { MatDialogRef } from '@angular/material/dialog';
import { ApplicationDialog } from './application-dialog';
import { IApplicationDialog } from './application-dialog.interface';

export abstract class SimpleDialog extends ApplicationDialog {
    public preventEnter: boolean = false;

    constructor(dialogRef: MatDialogRef<IApplicationDialog>) {
        super(dialogRef);

        this.dialogRef.keydownEvents()
            .subscribe((event: KeyboardEvent) => {
                if (event.keyCode === KEY_CODES.ENTER || event.keyCode === KEY_CODES.TAB) {
                    if (event.keyCode === KEY_CODES.TAB || this.preventEnter) {
                        event.preventDefault();
                    }

                    event.stopPropagation();
                }
            });
    }
}
