import { Directive, Injector } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationDialog, SnackBarService } from '../../layout';
import { AbstractForm } from './abstract-form';
import { FormManager } from './form.manager';

@Directive()
export abstract class DialogFormContainer<T, V, R, D extends T = T> extends ApplicationDialog {
    public readonly formComponent: AbstractForm<T, V>;

    public manager: FormManager;

    protected cancelMessage: string;

    protected cancelPanelClass: string;

    protected successMessage: string = 'changes_saved_successfully';

    protected snackBarService: SnackBarService;

    constructor(injector: Injector) {
        super(injector.get(MatDialogRef));

        this.manager = injector.get(FormManager);
        this.snackBarService = injector.get(SnackBarService);
    }

    public onSave(): void {
        this.formComponent.submit();
    }

    public onSubmit(): void {
        this.manager.sendSaveChangesRequest(
            this.saveChanges(this.getChangedData()),
            this.formComponent.form,
        ).subscribe((result: R) => this.onSaveSuccess(result));
    }

    public onCancel(): void {
        this.manager.openConfirmationDialog(this.cancelMessage, this.cancelPanelClass).pipe(
            filter(Boolean),
        ).subscribe(() => this.dialogRef.close());
    }

    protected onSaveSuccess(result: R): void {
        this.dialogRef.close(result);
        this.snackBarService.showDefaultSuccessNotification(this.successMessage);
    }

    protected getChangedData(): D {
        return this.formComponent.state.getEntity() as D;
    }

    protected abstract saveChanges(data: T): Observable<unknown>;
}
