import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { DialogService } from '../services';

@Injectable()
export class FormManager implements OnDestroy {
    public isLoading: boolean = false;

    protected dialog: DialogService;

    protected translate: TranslateService;

    protected destroy$: Subject<void> = new Subject<void>();

    constructor(injector: Injector) {
        this.dialog = injector.get(DialogService);
        this.translate = injector.get(TranslateService);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public sendSaveChangesRequest<T>(request$: Observable<T>, group: FormGroup): Observable<T> {
        if (this.isLoading) {
            return EMPTY;
        }

        this.isLoading = true;

        return request$.pipe(
            finalize(() => this.isLoading = false),
            takeUntil(this.destroy$),
            catchError((res: HttpErrorResponse) => {
                this.onSaveChangesFailure(res, group);

                return EMPTY;
            }),
        );
    }

    public openConfirmationDialog(titleStr?: string, classStr?: string, headerStr?: string): Observable<boolean> {
        const header = this.translate.instant(headerStr || 'confirmation');
        const title = this.translate.instant(titleStr || 'notifications.unsaved_changes');
        const panelClass = classStr || 'confirmation-dialog';

        return this.dialog.confirm({
            header,
            panelClass,
            title,
        });
    }

    protected onSaveChangesFailure(res: HttpErrorResponse, group: FormGroup): void {
        // should be declared
    }
}
