import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, of, switchMap } from 'rxjs';
import { AlertDialogComponent, ConfirmDialogComponent, IAlertDialogOptions, IConfirmDialogConfig } from '../../layout';
import { DIALOG_CONFIG } from '../constants';
import { filter } from 'rxjs/operators';
import * as keycodes from '@angular/cdk/keycodes';
import { TranslateService } from '@ngx-translate/core';

interface CustomMatDialogConfig<D = unknown> extends MatDialogConfig<D> {
    isCloseByEsc?: boolean;
}

@Injectable()
export class DialogService {
    constructor(
        private _dialog: MatDialog,
        private _translate: TranslateService,
    ) { }

    public closeAll(): void {
        this._dialog.closeAll();
    }

    public open<T, D = unknown, R = unknown>(
        component: ComponentType<T>,
        config?: CustomMatDialogConfig<D>,
        configForConfirmation?: IConfirmDialogConfig,
    ):
        MatDialogRef<T, R> {
        const dialogRef = this._dialog.open(component, {
            ...DIALOG_CONFIG,
            ...config,
            disableClose: true,
        });

        dialogRef.keydownEvents()
            .pipe(
                filter((event: KeyboardEvent) => event.keyCode === keycodes.ESCAPE),
                switchMap(() => configForConfirmation ? this.confirm(configForConfirmation) : of(true)),
                filter(Boolean),
            )
            .subscribe(() => dialogRef.close(false));

        return dialogRef;
    }

    public confirm(config: IConfirmDialogConfig): Observable<boolean> {
        return this.open<ConfirmDialogComponent, IConfirmDialogConfig, boolean>(ConfirmDialogComponent, {
            data: {
                ...DIALOG_CONFIG,
                ...config,
                header: config.header ?? this._translate.instant('confirmation'),
                isClosableByEsc: true,
            },
            disableClose: true,
            panelClass: config.panelClass ?? 'confirmation-dialog',
        }).afterClosed();
    }

    public alert(options: IAlertDialogOptions, panelClass?: string): Observable<void> {
        return this.open<AlertDialogComponent, IAlertDialogOptions, void>(AlertDialogComponent, {
            data: {
                ...DIALOG_CONFIG,
                ...options,
            },
            disableClose: true,
            panelClass,
        }).afterClosed();
    }
}
