import { ConnectedPosition, ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef, Input, OnDestroy,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import {
    debounceTime, filter, fromEvent, share, startWith, Subject, switchMap, takeUntil
} from 'rxjs';
import { POPUP_OVERLAY_POSITION_RIGHT } from '../constants';

@Component({
    selector: 'app-popup-on-hover',
    styleUrls: ['./popup-on-hover.component.scss'],
    templateUrl: './popup-on-hover.component.html',
})
export class PopupOnHoverComponent implements AfterViewInit, OnDestroy {
    @Input() public overlayPositions: ConnectedPosition[] = POPUP_OVERLAY_POSITION_RIGHT;

    @Input() public isWarn: boolean = false;

    @ViewChild('infoIcon') public icon: MatIcon;

    @ViewChild('popup') public popup: ElementRef<HTMLElement>;

    @ViewChild('copiedNotification', { read: TemplateRef })

    public isOpened: boolean = false;

    public readonly overlayScrollStrategy: ScrollStrategy;

    private _destroy$: Subject<void> = new Subject<void>();

    private readonly _debounceTime: number = 100;

    private readonly _closeDebounceTime: number = 10;

    constructor(
        private readonly _cdr: ChangeDetectorRef,
        private readonly _scrollStrategyOptions: ScrollStrategyOptions,
    ) {
        this.overlayScrollStrategy = this._scrollStrategyOptions.close();
    }

    public ngAfterViewInit(): void {
        const cdkOverlayOriginEl = this.icon._elementRef.nativeElement;

        const open$ = fromEvent<MouseEvent>(cdkOverlayOriginEl, 'mouseenter').pipe(
            takeUntil(this._destroy$),
            filter(() => !this.isOpened),
            switchMap((enterEvent: MouseEvent) =>
                fromEvent<MouseEvent>(document, 'mousemove').pipe(
                    startWith(enterEvent),
                    debounceTime(this._debounceTime),
                    filter((event: MouseEvent) => cdkOverlayOriginEl === event['target']),
                )),
            share(),
        );

        open$.subscribe(() => this._changeState(true));

        const close$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
            takeUntil(this._destroy$),
            debounceTime(this._closeDebounceTime),
            filter(() => this.isOpened),
            filter((event: MouseEvent) => this._isMovedOutside(cdkOverlayOriginEl, event)),
        );

        open$.pipe(
            switchMap(() => close$),
        ).subscribe(() => {
            this._changeState(false);
        });
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public connectedOverlayDetach(): void {
        this._changeState(false);
    }

    private _changeState(isOpened: boolean): void {
        this.isOpened = isOpened;
        this._cdr.markForCheck();
    }

    private _isMovedOutside(originElement: HTMLElement, event: MouseEvent): boolean {
        const target = event.target;

        if (target instanceof Node) {
            return !(originElement.contains(target) || this.popup.nativeElement.contains(target));
        }

        return true;
    }
}
