import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CloudPaginatorIntl extends MatPaginatorIntl {
    constructor(
        private _translateService: TranslateService,
    ) {
        super();
        this.redefineItemsPerPageLabel();
        this._resetPaginatorLabels();

        this._translateService.onLangChange.subscribe(() => this.redefineItemsPerPageLabel());
    }

    public redefineItemsPerPageLabel(): void {
        this.itemsPerPageLabel = this._translateService.instant('rows_per_page');
        this.changes.next();
    }

    private _resetPaginatorLabels(): void {
        this.firstPageLabel = this.lastPageLabel = this.previousPageLabel = this.nextPageLabel = '';
    }
}
