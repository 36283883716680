import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleDialog } from '../simple-dialog';

export interface IConfirmDialogConfig {
    confirmCheckboxLabel?: string;
    falseText?: string;
    footerButtonsClass?: string;
    header?: string;
    isClosableByEsc?: boolean;
    panelClass?: string;
    title: string;
    trueText?: string;
}

@Component({
    selector: 'app-confirm-dialog',
    styleUrls: ['./confirm-dialog.component.scss'],
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends SimpleDialog {
    public isClosableByEsc: boolean = true;

    public isChecked: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public context: IConfirmDialogConfig,
        dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {
        super(dialogRef);
    }

    public checkConfirmation(): void {
        this.isChecked = !this.isChecked;
    }
}
