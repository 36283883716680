import { IComponentWithForm } from '../models';

export function Validate(): MethodDecorator {
    return (target: () => void, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        descriptor.value = function (...params: unknown[]): PropertyDescriptor {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const context = this;

            if (!(context as IComponentWithForm).form.valid) {
                return;
            }

            return originalMethod.apply(context, params);
        };
    };
}
