export class FileSizeUtil {
    public static bytesToSize(bytesString: string): string {
        const bytes = parseInt(bytesString, 10);
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

        if (bytes === 0) {
            return '0 Byte';
        }

        const kilobyte = 1024;

        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(kilobyte)).toString(), 10);

        return `${Math.round(bytes / Math.pow(kilobyte, i))} ${sizes[i]}`;
    }
}
