import { RoutesKey } from '../enum';

export const ROUTES_MAP = {
    [RoutesKey.home]: [],
    [RoutesKey.licenses]: ['licenseViewAll'],
    [RoutesKey.companies]: ['businessViewAll'],
    [RoutesKey.updates]: ['versionUpdateViewAll'],
    [RoutesKey.user]: ['userViewAll', 'roleViewAll'],
    [RoutesKey.dashboard]: ['dashboardViewAll'],
    [RoutesKey.reports]: ['reportViewAll'],
    [RoutesKey.dealers]: ['dealerViewAll'],
    [RoutesKey.contacts]: ['dealerViewAll'],
    [RoutesKey.profile]: [],
};
