import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { apiConfig } from './apiconfig';
import { b2cPolicies } from './policies';

const isIE = window.navigator.userAgent.includes('MSIE ') || window.navigator.userAgent.includes('Trident/');

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            clientId: environment.msalClientId,
            knownAuthorities: [environment.authorityName],
            postLogoutRedirectUri: window.location.origin,
            redirectUri: `/`,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: environment.b2cScopes,
        },
    };
}

/*
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
    scopes: ['openid', 'profile'],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: apiConfig.b2cScopes,
};
