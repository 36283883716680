import { AbstractControl } from '@angular/forms';

export class UrlValidator {
    public static validate(url: AbstractControl): { invalidUrl: boolean } {
        const URL_REGEXP = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;

        const maxUrlLength = 2083;

        if (url.value && (url.value.lenght > maxUrlLength || !URL_REGEXP.test(url.value))) {
            return {
                invalidUrl: true,
            };
        }

        return null;
    }
}
