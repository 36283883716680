import { HttpParams } from '@angular/common/http';
import { isBoolean, isInteger } from 'lodash';

export class SearchRequestParametersUtils {
    public static getHttpRequestParams<T>(parameters: T): HttpParams {
        return Object.keys(parameters).reduce(
            (previousValue: HttpParams, key: string) =>
                parameters[key] || isBoolean(parameters[key]) || isInteger(parameters[key]) ?
                    previousValue.append(key, parameters[key]) :
                    previousValue
            , new HttpParams(),
        );
    }
}
