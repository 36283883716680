import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-expansion-menu',
    styleUrls: ['./expansion-menu.component.scss'],
    templateUrl: './expansion-menu.component.html',
})
export class ExpansionMenuComponent {
    @Input() public title: string = '';

    @Input() public opened: boolean = false;

    public toggle(): void {
        this.opened = !this.opened;
    }
}
