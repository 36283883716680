import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { scrollToElement } from '../helpers';
import { ISearchResponse } from './models';

interface ISearchParams {
    page?: number;
}

@Directive()
export abstract class AbstractSearchListView<TParams, TResults> implements OnInit, OnDestroy {
    public searchParameters: TParams = null;

    public items: TResults[];

    public totalCount: number;

    protected readonly numberKeys: string[] = ['page', 'perPage', 'sortDirection'];

    protected readonly booleanKeys: string[] = [];

    protected isLoading: boolean;

    protected destroy$: Subject<void> = new Subject<void>();

    protected abstract router: Router;

    protected abstract route: ActivatedRoute;

    protected requestSubscription: Subscription;

    protected page: number;

    public ngOnInit(): void {
        this.loadDataOnChangedQueryParams();
    }

    public loadDataOnChangedQueryParams(): void {
        this.route.queryParams.pipe(
            takeUntil(this.destroy$),
        ).subscribe((searchParameters: Params) => {
            this.searchParameters = this.convertQueryParamsToModel(searchParameters);

            if (!isEmpty(this.searchParameters)) {
                this.loadData();
            } else {
                this.setDefaultParams();
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onSearch(searchParameters: TParams): void {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: searchParameters,
        });
    }

    public loadData(): void {
        this.isLoading = true;

        const params = this.mapSearchParam();

        const subscribe = this.getData(params).pipe(
            filter(() => this.requestSubscription === subscribe),
            finalize(() => this.isLoading = false),
        ).subscribe((result: ISearchResponse<TResults>) => {
            this.items = result.items;
            this.totalCount = result.totalCount;

            this.scrollToElement((params as ISearchParams)?.page);
        });

        this.requestSubscription = subscribe;
    }

    protected scrollToElement(page: number): void {
        if (this.page !== page) {
            scrollToElement('.scroll-to-table');
            this.page = page;
        }
    }

    protected convertQueryParamsToModel(params: Params): TParams {
        return Object.fromEntries<unknown>(Object.entries({ ...params })
            .map(([key, value]: [string, string]) => {
                let v: unknown = value;

                if (this.numberKeys.includes(key)) {
                    if (v === '') {
                        v = null;
                    } else {
                        v = Number(v);
                    }
                }

                if (this.booleanKeys.includes(key)) {
                    v = v === 'true';
                }

                return [key, v];
            })) as TParams;
    }

    protected abstract setDefaultParams(): void;

    protected abstract mapSearchParam(): TParams;

    protected abstract getData(params: TParams): Observable<ISearchResponse<TResults>>;
}
