<mat-icon #infoIcon aria-hidden="false" aria-label="subitems" class="info-icon" [class.warning-icon]="isWarn"
          #cdkOverlayOrigin="cdkOverlayOrigin" cdkOverlayOrigin>{{ isWarn ? 'error_outline' : 'info_outline' }}</mat-icon>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
    [cdkConnectedOverlayOpen]="isOpened"
    [cdkConnectedOverlayPositions]="overlayPositions"
    [cdkConnectedOverlayScrollStrategy]="overlayScrollStrategy"
    (detach)="connectedOverlayDetach()"
>
    <div #popup class="info-popup">
        <ng-content></ng-content>
    </div>
</ng-template>

