import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarType } from '../enums';
import { ISnackBarData } from './models';

@Component({
    selector: 'app-snack-bar-custom',
    styleUrls: ['./snack-bar-custom.component.scss'],
    templateUrl: './snack-bar-custom.component.html',

})
export class SnackBarCustomComponent implements OnInit {
    public readonly snackBarType: typeof SnackBarType = SnackBarType;

    constructor(
        public snackBarRef: MatSnackBarRef<SnackBarCustomComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData,
    ) {}

    public ngOnInit(): void {
        document.querySelector('snack-bar-container')?.addEventListener(
            'click',
            (event: Event) => event.stopPropagation(),
        );
    }
}
